
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.favorite-service {
  position: relative;
  border-radius: 12px;
  // padding: 16px 16px 42px;
  padding: 16px;
  background: $color-white;
  transition: box-shadow $base-animation-time $base-animation-function;

  @include for-size(phone-portrait-down) {
    padding-right: 12px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  &__flag {
    margin-left: auto;
    margin-right: 0;
    color: $color-yellow;
  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $color-black;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;
  }

  &__info {
    @include hover(true) {
      fill: $color-client-portal-logo;
    }
  }

  .esmp-tag {
    font-size: 11px;
    line-height: 16px;
  }

  &:hover {
    box-shadow: $base-shadow-xl;
  }
}
